import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGamepadAlt } from "@fortawesome/pro-solid-svg-icons"
import React from "react"
import Layout from "../components/layout"
import Tilt from 'react-tilt'
import { Link } from "gatsby"
import { useRandomNextCard } from "../hooks/dilemmas"

const tasty = {
  fontFamily: 'TastySemilight'
};

const HowtoPage = () => {
  const [nextCard] = useRandomNextCard(null)

  return (
    <Layout>
      <div className="container mx-auto py-12 lg:py-20 xl:py-24 px-8">
        <h1 className="font-mono font-semibold text-4xl lg:text-5xl text-center">How to play</h1>
        <div className="flex flex-col lg:flex-row items-center xl:w-10/12 mx-auto my-6 md:my-12">
          <Tilt options={{ scale: 1.05, max: 20 }} className="w-full lg:w-1/2 lg:mr-16 max-w-xl">
            <Link to={nextCard.path} className="block no-underline  my-8 md:my-12 p-6 lg:p-8 bg-yellow text-white rounded shadow-lg" style={{ transform: "rotate(-2deg)"}}>
              <h2 className="text-xl lg:text-2xl mb-4 font-mono font-semibold">Singleplayer</h2>
              <ul>
                <li className="flex items-center mt-4">
                  <div className="flex items-center justify-center rounded-full bg-yellow-600 h-8 w-8 mr-4">
                    <img src={"/images/chantal-1.svg"} alt="" className="" />
                  </div>
                  <p className="lg:text-lg">Open a dilemma</p>
                </li>
                <li className="flex items-center mt-4">
                  <div className="flex items-center justify-center rounded-full bg-yellow-600 h-8 w-8 mr-4">
                    <img src={"/images/chantal-2.svg"} alt="" className="" />
                  </div>
                  <p className="lg:text-lg">Pick your side</p>
                </li>
                <li className="flex items-center mt-4">
                  <div className="flex items-center justify-center rounded-full bg-yellow-600 h-8 w-8 mr-4">
                    <img src={"/images/chantal-3.svg"} alt="" className="" />
                  </div>
                  <p className="lg:text-lg">Compare with other developers</p>
                </li>
              </ul>
            </Link>
          </Tilt>

          <Tilt options={{ scale: 1.05, max: 20 }} className="w-full lg:w-1/2 lg:ml-16 max-w-xl">
            <Link to={nextCard.path} className="block no-underline my-8 md:my-12 p-6 lg:p-8 bg-red text-white rounded shadow-lg" style={{ transform: "rotate(2deg)"}}>
              <h2 className="text-xl lg:text-2xl mb-4 font-mono font-semibold">Multiplayer</h2>
              <ul>
                <li className="flex items-center mt-4">
                  <div className="flex items-center justify-center rounded-full bg-red-600 h-8 w-8 mr-4">
                    <img src={"/images/chantal-1.svg"} alt="" className="" />
                  </div>
                  <p className="lg:text-lg">Start a video call with other devs</p>
                </li>
                <li className="flex items-center mt-4">
                  <div className="flex items-center justify-center rounded-full bg-red-600 h-8 w-8 mr-4">
                    <img src={"/images/chantal-2.svg"} alt="" className="" />
                  </div>
                  <p className="lg:text-lg">Share your screen & open a dilemma</p>
                </li>
                <li className="flex items-center mt-4">
                  <div className="flex items-center justify-center rounded-full bg-red-600 h-8 w-8 mr-4">
                    <img src={"/images/chantal-3.svg"} alt="" className="" />
                  </div>
                  <p className="lg:text-lg">Fight. Discuss. Laugh.</p>
                </li>
              </ul>
            </Link>
          </Tilt>
        </div>
        <div className="flex justify-center">
          <Link to={nextCard.path} className="c-button c-button--lg">
            Let's play <FontAwesomeIcon icon={faGamepadAlt} className="ml-2" />
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default HowtoPage
